import React from "react";
import "./Testimonials.css";
const Testimonials = () => {
  return (
    <div className="Testimonials" id="testimonials">
      <h2>Testimonials</h2>
      <div className="container">
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Choosing Namco Civil &amp; Building Construction for our project was the best decision we
            made. From the initial consultation to the final walkthrough, the
            professionalism and dedication of their team were evident. They
            delivered exceptional results while adhering to the highest
            standards of quality and craftsmanship.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Sipho Dlamini</p>
        </div>
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Working with Namco Civil &amp; Building Construction has been an exceptional experience
            from start to finish. Their dedication to quality, professionalism,
            and customer satisfaction is truly commendable. From the initial
            consultation to the final touches, every step of the process was
            executed with precision and care.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Mthobisi Magubane</p>
        </div>
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Throughout the process, communication was seamless, and they were
            always receptive to our input and concerns. Their attention to
            detail and commitment to customer satisfaction surpassed our
            expectations. Thanks to their expertise and efficiency, our project
            was completed on time and within budget. We are thrilled with the
            outcome and would highly recommend Namco Civil &amp; Building Construction to anyone
            seeking reliable, top-notch construction services. They truly go
            above and beyond to ensure their clients' vision becomes a reality.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Nosipho</p>
        </div>
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Namco Civil &amp; Building Construction provided exceptional service and delivered
            outstanding results. Their attention to detail, professionalism, and
            commitment to quality exceeded our expectations. We highly recommend
            Namco Civil &amp; Building Construction for any construction project.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Bheki</p>
        </div>
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Namco Civil &amp; Building Construction exceeded all our expectations. Their
            professionalism, attention to detail, and dedication to quality
            craftsmanship were exceptional. From concept to completion, their
            team ensured our project was executed seamlessly, on time, and
            within budget. Their expertise and commitment to customer
            satisfaction were evident every step of the way. We couldn't be
            happier with the results and highly recommend Namco Civil &amp; Building Construction to
            anyone in need of reliable, top-quality construction services.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Joe Doe</p>
        </div>
        <div className="text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <h3>
            Namco Civil &amp; Building Construction delivered outstanding results. Their
            professionalism, attention to detail, and commitment to quality
            craftsmanship were exemplary. From start to finish, their team
            ensured our project was completed efficiently and to our utmost
            satisfaction. We are thrilled with the outcome and highly recommend
            Namco Civil &amp; Building Construction for their exceptional service and expertise in the
            construction industry.
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            enable-background="new 0 0 52 52"
          >
            <g>
              <path
                d="M44.3,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H34.4v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H44.3z"
              />
              <path
                d="M19.5,6.5c-9,0-15.9,7-15.9,16V44c0,0.8,0.7,1.5,1.5,1.5h17c0.8,0,1.5-0.7,1.5-1.5V27
		c0-0.8-0.7-1.5-1.5-1.5H9.6v-3c0-5,4.9-10,9.9-10h2.6c0.8,0,1.5-0.7,1.5-1.5V8c0-0.8-0.7-1.5-1.5-1.5H19.5z"
              />
            </g>
            <path d="M30.5,8" />
          </svg>
          <p>- Loveness Ngubani</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;