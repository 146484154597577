import React from "react";
import Logo from "../../Images/Logo.png";
import Team from "../../Images/Team.png";
import "./Home.css";
const Home = ({ scrollToSection }) => {
   return (
      <div className="Home" id="home">
         <div className="text">
            <h1>
               Are you looking to <b>elevate the aesthetics</b> of your space?
            </h1>
            <h1>We are here to make it happen</h1>
            <div className="btn">
               <button
                  onClick={() => {
                     scrollToSection("contact");
                  }}
               >
                  <p>Contact Us</p>
               </button>
            </div>
         </div>
         <div className="imgs">
            <svg
               id="Layer_2"
               data-name="Layer 2"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 1068.38 1067.47"
            >
               <defs>
                  <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
                     <feOffset dx="7" dy="7" />
                     <feGaussianBlur result="blur" stdDeviation="31" />
                     <feFlood flood-color="#000" flood-opacity=".2" />
                     <feComposite in2="blur" operator="in" />
                     <feComposite in="SourceGraphic" />
                  </filter>
                  <clipPath id="clippath">
                     <rect
                        class="cls-2"
                        width="577.02"
                        height="577.02"
                        rx="10.21"
                        ry="10.21"
                     />
                  </clipPath>
                  <filter id="drop-shadow-2" filterUnits="userSpaceOnUse">
                     <feOffset dx="7" dy="7" />
                     <feGaussianBlur result="blur-2" stdDeviation="31" />
                     <feFlood flood-color="#000" flood-opacity=".2" />
                     <feComposite in2="blur-2" operator="in" />
                     <feComposite in="SourceGraphic" />
                  </filter>
               </defs>
               <g id="Home">
                  <g id="Team" class="cls-3">
                     <g class="cls-4">
                        <image
                           width="735"
                           height="441"
                           transform="translate(-228.99 -21.99) scale(1.41)"
                           href={Team}
                        />
                     </g>
                  </g>
                  <g id="Logo">
                     <rect
                        class="cls-1"
                        x="390.65"
                        y="390.38"
                        width="577.02"
                        height="577.02"
                        rx="10.21"
                        ry="10.21"
                     />
                     <image
                        width="300"
                        height="240"
                        transform="translate(480.12 519.67) scale(1.33)"
                        href={Logo}
                     />
                  </g>
               </g>
            </svg>
         </div>
      </div>
   );
};

export default Home;
