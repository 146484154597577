import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Namco Civil &amp; Building Construction </p>
      <p>34 Kayburn Avenue Randpark Ridge</p>
    </div>
  );
};

export default Footer;