import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Projects from "./Pages/Projects/Projects";
import Services from "./Pages/Services/Services";
import Testimonials from "./Pages/Testimonials/Testimonials";
function App() {
  const [hideHeader, setHideHeader] = useState(true);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      let isScrolledDown = false;
      if (currentScrollPos > 0) {
        isScrolledDown = false;
      } else {
        isScrolledDown = true;
      }
      setHideHeader(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="App">
      {!hideHeader && <Header scrollToSection={scrollToSection} />}
      <Home scrollToSection={scrollToSection} />
      <About />
      <Services />
      <Projects />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
