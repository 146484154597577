import React, { useState, useEffect } from "react";
import "./Projects.css";
import Masonry from "react-responsive-masonry";
import axios from "axios";

const Projects = () => {
   const [images, setImages] = useState([]);
   const [showNum, setShowNum] = useState(12);
   const [showImg, setShowImg] = useState(false);
   const [viewedImg, setViewedImg] = useState();

   const viewImg = (image) => {
      setViewedImg(image);
   };

   const getImages = async () => {
      const response = await axios.get("/api/images/projects");
      console.log(response.data);
      setImages(response.data);
   };

   useEffect(() => {
      getImages();
   }, []);

   return (
      <div className="Projects" id="projects">
         <h2>Projects</h2>
         <div className="collage" style={showImg ? { opacity: "20%" } : null}>
            <Masonry columnsCount={3} gutter="1em">
               {images.slice(0, showNum).map((image, i) => (
                  <img
                     key={i}
                     src={image.image}
                     style={{
                        width: "100%",
                        height: image.height,
                        display: "block",
                     }}
                     onClick={() => {
                        setShowImg(true);
                        viewImg(image.image);
                     }}
                  />
               ))}
            </Masonry>
            <button onClick={() => setShowNum(showNum + 3)}>view more</button>
         </div>
         <div
            className="viewImg"
            style={showImg ? { display: "block" } : { display: "none" }}
         >
            <div className="photo">
               <img src={viewedImg} alt="" />
            </div>
            <button onClick={() => setShowImg(false)}>
               <p>close</p>
            </button>
         </div>
      </div>
   );
};

export default Projects;
